<template>
    <div class="absolute top-0 left-0 w-full h-full" @mouseover="pauseTimeout" @mouseleave="startTimeout">
        <Icon class="absolute top-1 right-1 text-lg cursor-pointer" name="close" @click="emit('notificationClose')" />

        <div ref="progressBar" class="progressBar absolute left-0 bottom-0 h-0.5 w-full bg-black"></div>
    </div>
</template>

<script setup lang="ts">
    const emit = defineEmits(['notificationClose'])

    const time = ref(5000)
    const progressBar = ref()
    const timeout = ref<NodeJS.Timeout>()
    const startedTime = ref(0)

    function startTimeout() {
        startedTime.value = new Date().getTime()
        progressBar.value.style.animationPlayState = 'running'

        timeout.value = setTimeout(() => {
            emit('notificationClose')
        }, time.value)
    }

    function pauseTimeout() {
        const currentTime = new Date().getTime()
        time.value = time.value - (currentTime - startedTime.value)

        progressBar.value.style.animationPlayState = 'paused'

        clearTimeout(timeout.value)
    }

    onMounted(() => {
        startTimeout()
    })

    onBeforeUnmount(() => {
        clearTimeout(timeout.value)
    })
</script>

<style scoped lang="scss">
    .progressBar {
        animation: progressBar 5s linear;
    }

    @keyframes progressBar {
        from {
            @apply w-full;
        }

        to {
            @apply w-0;
        }
    }
</style>
